import { Dict } from "./../../models/interfaces";
import { Dispatch } from "redux";

import api from "../../api";
import { toastr } from "react-redux-toastr";
import { types as commonTypes } from "./common";
import { State } from "../interfaces";
import User from "../../models/user";
import Company from "../../models/company";
import CompanyUser from "../../models/companyUser";
import { Document, DocumentType } from "../../models/document";
import ShippingAddress from "../../models/shippingAddress";
import { CrudListRequestModel } from "../../api/models";

// action types
export const types = {
  // TAB
  TAB_CHANGED: "USER/TAB_CHNANGED",

  // User profile
  FETCH_PROFILE_REQUEST: "USER/FETCH_PROFILE_REQUEST",
  FETCH_PROFILE_SUCCESS: "USER/FETCH_PROFILE_SUCCESS",
  FETCH_PROFILE_ERROR: "USER/FETCH_PROFILE_ERROR",

  // Update user profile
  UPDATE_PROFILE_REQUEST: "USER/UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "USER/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR: "USER/UPDATE_PROFILE_ERROR",

  // Get user list
  COMPANY_USER_REQUEST: "USER/COMPANY_USER_REQUEST",
  COMPANY_USER_SUCCESS: "USER/COMPANY_USER_SUCCESS",
  COMPANY_USER_ERROR: "USER/COMPANY_USER_ERROR",

  // Get shipping address list
  SHIPPING_ADDRESS_REQUEST: "USER/SHIPPING_ADDRESS_REQUEST",
  SHIPPING_ADDRESS_SUCCESS: "USER/SHIPPING_ADDRESS_SUCCESS",
  SHIPPING_ADDRESS_ERROR: "USER/SHIPPING_ADDRESS_ERROR",

  // Company profile
  FETCH_COMPANY_REQUEST: "USER/FETCH_COMPANY_REQUEST",
  FETCH_COMPANY_SUCCESS: "USER/FETCH_COMPANY_SUCCESS",
  FETCH_COMPANY_ERROR: "USER/FETCH_COMPANY_ERROR",

  // Update Company user profile
  UPDATE_COMPANY_PROFILE_REQUEST: "USER/UPDATE_COMPANY_PROFILE_REQUEST",
  UPDATE_COMPANY_PROFILE_SUCCESS: "USER/UPDATE_COMPANY_PROFILE_SUCCESS",
  UPDATE_COMPANY_PROFILE_ERROR: "USER/UPDATE_COMPANY_PROFILE_ERROR",

  // Update Company user profile
  UPDATE_COMPANY_ADDRESS_REQUEST: "USER/UPDATE_COMPANY_ADDRESS_REQUEST",
  UPDATE_COMPANY_ADDRESS_SUCCESS: "USER/UPDATE_COMPANY_ADDRESS_SUCCESS",
  UPDATE_COMPANY_ADDRESS_ERROR: "USER/UPDATE_COMPANY_ADDRESS_ERROR",

  // Get document list
  DOCUMENT_REQUEST: "USER/DOCUMENT_REQUEST",
  DOCUMENT_SUCCESS: "USER/DOCUMENT_SUCCESS",
  DOCUMENT_ERROR: "USER/DOCUMENT_ERROR",

  // Delete document
  DELETE_DOCUMENT_REQUEST: "USER/DELETE_DOCUMENT_REQUEST",
  DELETE_DOCUMENT_SUCCESS: "USER/DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_ERROR: "USER/DELETE_DOCUMENT_ERROR",

  TERMS_REQUEST: "AUTH/TERMS_REQUEST",
  TERMS_GOOD: "AUTH/TERMS_GOOD",
  TERMS_SUCCESS: "AUTH/TERMS_SUCCESS",
  TERMS_ERROR: "AUTH/TERMS_ERROR",
};

export enum UserTabs {
  UserProfile = "User Info",
  CompanyProfile = "My Company Profile",
  ShippingAddress = "Shipping Address",
  UserManagement = "User Management",
  TermsOfUse = "Terms of use",
  Performance = "Performance",
  PlaceOrderForCustomer = "Place Order for Customer",
  CustomerOrders = "Customer Orders",
  HighValueOrderRequest = "High Value Order Request",
  CustomerPaymentDueNotification = "Customer Payment Due Notification",
  MyProfile = "My Profile",
}

export interface UserState {
  currentTab: UserTabs;
  user?: User;
  userProfileLoaded: boolean;
  isLoading: boolean;
  isListLoading?: boolean;
  companyUsers?: CompanyUser[];
  isListLoaded?: boolean;
  isUserLoading?: boolean;
  isUserLoaded?: boolean;
  shippingAddress?: ShippingAddress[];
  totalCount?: number;
  perPageCount?: number;
  companyProfile?: Company;
  companyProfileLoaded?: boolean;
  isDocumentLoading: boolean;
  isDocumentLoaded?: boolean;
  documents?: Document[];
  documentTypes?: DocumentType[];
  isTermsLoading: boolean;
  isTermsLoaded: boolean;
  terms?: string;
}

const initialState: UserState = {
  currentTab: UserTabs.UserProfile,
  userProfileLoaded: false,
  isLoading: false,
  isListLoading: false,
  isListLoaded: false,
  isUserLoading: false,
  isUserLoaded: false,
  companyProfileLoaded: false,
  isDocumentLoading: false,
  isDocumentLoaded: false,
  isTermsLoading: false,
  isTermsLoaded: false,
};

export default (state: UserState = initialState, action: any): UserState => {
  switch (action.type) {
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
      };
    // tab
    case types.TAB_CHANGED:
      return {
        ...state,
        currentTab: action.data.tab,
      };
    // profile
    case types.FETCH_PROFILE_REQUEST:
      return { ...state, isLoading: true };
    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.data ? action.data.result : null,
        userProfileLoaded: !!action.data,
      };
    case types.FETCH_PROFILE_ERROR:
      return { ...state, isLoading: false };

    // company user
    case types.COMPANY_USER_REQUEST:
      return { ...state, isUserLoading: true };
    case types.COMPANY_USER_SUCCESS:
      const companyUsers =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.companyUsers ? state.companyUsers : []),
              ...action.data.result,
            ];
      return {
        ...state,
        isUserLoading: false,
        companyUsers,
        isUserLoaded: !!action.data,
        totalCount: action.data ? action.data.totalUsers : 1,
        perPageCount: 10,
      };
    case types.COMPANY_USER_ERROR:
      return { ...state, isUserLoading: false, totalCount: 0 };

    // shipping address
    case types.SHIPPING_ADDRESS_REQUEST:
      return { ...state, isListLoading: true };
    case types.SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        isListLoading: false,
        shippingAddress: action.data ? action.data.result : null,
        isListLoaded: !!action.data,
      };
    case types.SHIPPING_ADDRESS_ERROR:
      return { ...state, isListLoading: false };

    // Comapny profile
    case types.FETCH_COMPANY_REQUEST:
      return { ...state, isLoading: true };
    case types.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companyProfile: action.data ? action.data.result : null,
        companyProfileLoaded: !!action.data,
      };
    case types.FETCH_COMPANY_ERROR:
      return { ...state, isLoading: false };
    // Comapny profile update
    case types.UPDATE_COMPANY_PROFILE_REQUEST:
      return { ...state };
    case types.UPDATE_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companyProfile: action.data ? action.data.result : null,
      };
    case types.UPDATE_COMPANY_PROFILE_ERROR:
      return { ...state };
    // Comapny address update
    case types.UPDATE_COMPANY_ADDRESS_REQUEST:
      return { ...state };
    case types.UPDATE_COMPANY_ADDRESS_SUCCESS:
      return {
        ...state,
        companyProfile: action.data ? action.data.result : null,
      };
    case types.UPDATE_COMPANY_ADDRESS_ERROR:
      return { ...state };

    // documents
    case types.DOCUMENT_REQUEST:
      return { ...state, isDocumentLoading: true };
    case types.DOCUMENT_SUCCESS:
      return {
        ...state,
        isDocumentLoading: false,
        documents:
          action.data && action.data.result ? action.data.result.list : null,
        documentTypes:
          action.data && action.data.result ? action.data.result.type : null,
        isDocumentLoaded: true,
      };
    case types.DOCUMENT_ERROR:
      return { ...state, isDocumentLoading: false };
    case types.TERMS_REQUEST:
      return {
        ...state,
        isTermsLoading: true,
        isTermsLoaded: false,
      };
    case types.TERMS_GOOD:
      return {
        ...state,
        terms:
          action.data && action.data.result ? action.data.result.terms : "",
        isTermsLoading: false,
        isTermsLoaded: true,
      };
    case types.TERMS_ERROR:
      return {
        ...state,
        isTermsLoading: false,
        isTermsLoaded: true,
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  changeTab: (tab: UserTabs) => (dispatch: Dispatch) =>
    dispatch({ type: types.TAB_CHANGED, data: { tab } }),
  fetchProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    try {
      const response = await api.user.getStudentProfile();
      const { data } = response;
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.FETCH_PROFILE_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  updateProfile: (userData: User, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });
    try {
      const response = await api.user.updateProfile(userData);
      const { data } = response;
      if (data.response === "Failure") {
        dispatch({
          type: types.UPDATE_PROFILE_ERROR,
          data: data.errorMsg,
        });
        toastr.error("Error", data.errorMsg);
      } else {
        dispatch({
          type: types.UPDATE_PROFILE_SUCCESS,
          data: response.data.user,
        });
        toastr.success("Success", "Profile updated successfully");
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: (error as any)?.response?.data?.errors ?? "",
      });
      toastr.error("Error", "Error updating profile");
      throw error;
    }
  },
  companyUserList: (request: CrudListRequestModel, page: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.COMPANY_USER_REQUEST });
    try {
      const response = await api.user.getList(request, page);
      const { data } = response;
      dispatch({ type: types.COMPANY_USER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.COMPANY_USER_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  companyUserCreate: (
    userData: CompanyUser,
    userToken: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });
    try {
      const response = await api.user.companyUserCreate(userData, userToken);
      const { data } = response;
      if (data.response === "Failure") {
        dispatch({
          type: types.UPDATE_PROFILE_ERROR,
          data: data.errorMsg,
        });
        toastr.error("Error", data.errorMsg);
      } else {
        dispatch({
          type: types.UPDATE_PROFILE_SUCCESS,
          data: response.data.user,
        });
        toastr.success(
          "Success",
          userData.userID
            ? "User updated successfully"
            : "User created successfully"
        );
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: (error as any)?.response?.data?.errors ?? "",
      });
      toastr.error("Error", "Error updating user");
      throw error;
    }
  },
  companyUserDelete: (
    userID: number,
    userToken: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });
    try {
      const response = await api.user.companyUserDelete(userID, userToken);
      dispatch({
        type: types.UPDATE_PROFILE_SUCCESS,
        data: response.data.user,
      });
      toastr.success("Success", "User deleted successfully");
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: (error as any)?.response?.data?.errors?? '',
      });
      toastr.error("Error", "Error deleting profile");
      throw error;
    }
  },
  shippingAddressList: (request: CrudListRequestModel) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.SHIPPING_ADDRESS_REQUEST });
    try {
      const response = await api.user.shippingAddressList(request);
      const { data } = response;
      dispatch({ type: types.SHIPPING_ADDRESS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.SHIPPING_ADDRESS_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  shippingAddressCreate: (
    userData: ShippingAddress,
    userToken: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });
    try {
      const response = await api.user.shippingAddressCreate(
        userData,
        userToken
      );
      dispatch({
        type: types.UPDATE_PROFILE_SUCCESS,
        data: response.data.user,
      });
      toastr.success(
        "Success",
        userData.userShippingID
          ? "Address updated successfully"
          : "Address created successfully"
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: (error as any)?.response?.data?.errors?? '',
      });
      toastr.error("Error", "Error updating address");
      throw error;
    }
  },
  shippingAddressDelete: (
    userShippingID: number,
    userToken: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });
    try {
      const response = await api.user.shippingAddressDelete(
        userShippingID,
        userToken
      );
      dispatch({
        type: types.UPDATE_PROFILE_SUCCESS,
        data: response.data.user,
      });
      toastr.success("Success", "Address deleted successfully");
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_PROFILE_ERROR,
        data: (error as any)?.response.data.errors,
      });
      toastr.error("Error", "Error updating address");
      throw error;
    }
  },
  getCompanyProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    try {
      const response = await api.user.getCompanyProfile();
      const { data } = response;
      dispatch({ type: types.FETCH_COMPANY_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.FETCH_COMPANY_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  companyAddressUpdate: (userData: Company, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.UPDATE_COMPANY_ADDRESS_REQUEST });
    try {
      const response = await api.user.companyAddressUpdate(userData);
      dispatch({
        type: types.UPDATE_COMPANY_ADDRESS_SUCCESS,
        data: response.data,
      });
      toastr.success(
        "Success",
        userData.userID
          ? "Address updated successfully"
          : "Address created successfully"
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_COMPANY_ADDRESS_ERROR,
        data: (error as any)?.response.data.errors,
      });
      toastr.error("Error", "Error updating address");
      throw error;
    }
  },
  companyProfileUpdate: (userData: Company, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.UPDATE_COMPANY_PROFILE_REQUEST });
    try {
      const response = await api.user.companyProfileUpdate(userData);
      dispatch({
        type: types.UPDATE_COMPANY_PROFILE_SUCCESS,
        data: response.data,
      });
      toastr.success(
        "Success",
        userData.userID
          ? "Company profile updated successfully"
          : "Company profile created successfully"
      );
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.UPDATE_COMPANY_PROFILE_ERROR,
        data: (error as any)?.response.data.errors,
      });
      toastr.error("Error", "Error updating company profile");
      throw error;
    }
  },
  documentList: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.DOCUMENT_REQUEST });
    try {
      const response = await api.user.documentList();
      const { data } = response;
      dispatch({ type: types.DOCUMENT_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.DOCUMENT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  documentDelete: (documentID: number, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({ type: types.DELETE_DOCUMENT_REQUEST });
    try {
      const response = await api.user.documentDelete(documentID);
      dispatch({
        type: types.DELETE_DOCUMENT_SUCCESS,
        data: response.data.user,
      });
      toastr.success("Success", "Document deleted successfully");
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({
        type: types.DELETE_DOCUMENT_ERROR,
        data: (error as any)?.response.data.errors,
      });
      toastr.error("Error", "Error deleting document");
      throw error;
    }
  },

  getTerms: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);

    dispatch({ type: types.TERMS_REQUEST });
    try {
      const response = await api.user.getTerms(state.authUser.authToken);
      const { data } = response;
      dispatch({ type: types.TERMS_GOOD, data });
    } catch (error) {
      dispatch({ type: types.TERMS_ERROR });
      throw error;
    }
  },
};
