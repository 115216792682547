import axios from 'axios';

const unauthorizedApiResponseInterceptor = (
  onUnauthorizedResponse?: () => void
) => {
  axios.interceptors.response.use(undefined, (err) => {
    if (typeof err.response === 'undefined') {
      if (onUnauthorizedResponse) {
        onUnauthorizedResponse();
      }
    }
    throw err;
  });
};

export default unauthorizedApiResponseInterceptor;
